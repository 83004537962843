import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import setGlobalState from '../../../../redux/setGlobalState';
import EnterButton from '../../components/enterButton/EnterButton';
import LoginInput from '../../components/loginInput/LoginInput';
import * as css from './SignUpForm.css';
import LoginByGoogle from '../../components/loginByBtn/LoginByGoogle';
import {getIsExistingUser, signUpApple, signUpGoogle} from '../../../../redux/user/userActions';
import {useHistory} from 'react-router-dom';
import {useFormikContext} from 'formik';
import LanguageSelect from '../../signIn/languageSelect/LanguageSelect';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import LoginPhoneInput from '../../components/loginPhoneInput/LoginPhoneInput';
// import LoginByApple, { isAppleDevice } from '../../components/loginByBtn/LoginByApple';
// import {IS_PRODUCTION} from '../../../../config/config';

export const onSignUp = async(response,history,form) =>{
    const user = await signUpGoogle({glAccessToken:response.credential});
    signUpApi(user, history, form);
};

export const signUpApi = async(user, history, form) =>{
    const res=await getIsExistingUser(user);
    if(res?.isExistingUser){
        setGlobalState('ui.login.existingUserEmail',user.email);
        history.push('login');
    }
    else {
        setGlobalState('ui.login.isMissingPhone',true);
        form.setValues({...form.values, ...user});
    }
};
export const onAppleSignUp = async (response, history, form) => {
    try {
        const user = await signUpApple(response);
        signUpApi(user, history, form);
    } catch (error) {
        console.log(error);
    }
};

function SignUpForm() {
    const {t, i18n} = useTranslation('login');
    const form = useFormikContext();
    const history = useHistory();
    const getTokenToSignUpXHR=useSelector(s=>s.user.getTokenToSignUpXHR);

    useEffect(()=>{
        setGlobalState('ui.layout.isSignUp',true);
        if(getTokenToSignUpXHR==='SUCCESS' )
            setGlobalState('user.getTokenToSignUpXHR','UPDATED');
    },[]);

    useEffect(() => {
        setImmediate(() => form.validateForm());
    }, [i18n.language]);

    return (
        <css.container>
            <LanguageSelect />
            <css.header data-cy = 'signupTitle'>{t('signUpTitle')}</css.header>
            <css.form>
                <css.wrapGoogle>
                    <LoginByGoogle text={'signup_with'} callback={(response)=>onSignUp(response,history,form)}/>
                </css.wrapGoogle>
                {/* {!IS_PRODUCTION && isAppleDevice() &&
                    <css.apple>
                        <LoginByApple onSuccess={onAppleSignUp}/>
                    </css.apple>} */}
                <css.likeBeforeSignUp>{t('likeBefore')}</css.likeBeforeSignUp>
                <LoginInput name="nameFirst" text={t('nameFirst')} description={t('nameFirstDesc')} data-cy ="nameFirst" />
                <LoginInput name="nameLast"  text={t('nameLast')}  description={t('nameLastDesc')}data-cy ="nameLast" />
                <LoginInput name="email"     text={t('email')}     description={t('emailUpDesc')} type='email' data-cy ="email"  />
                <LoginPhoneInput/>
                <css.button>
                    <EnterButton text={t('signUpButton')} actionName='getTokenToSignUp' buttonType= 'signup' />
                </css.button>
              
                <css.linkWarp data-cy="linkToLogin">{t('linkQuestionTextSignUp')}
                    <Link to={'/Login'}>{t('linkTextSignUp')}</Link>
                </css.linkWarp>
      
            </css.form>
        </css.container>
    );
}

export default SignUpForm;
