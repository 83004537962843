import {useTranslation} from 'react-i18next';
import * as css from './windowItems.css';
import TicketProgress from '../../../tickets/components/ticketRow/ticketProgress/TicketProgress';
import {DEFAULT_COLOR} from '../../../../globalVariables/GlobalVariables';
import {formatRealtimeDecimal, formatRealtimeNumber} from '../../functions';

const formatNumber = (number: any = 0, kFormat?: boolean) => {
    if(Number.isInteger(number))
        return formatRealtimeNumber(number || 0, kFormat);
    const isDecimal = Math.floor(number) !== number;
    if(isDecimal) formatRealtimeDecimal(number || 0, true);
    return number;
};
function MainDetail({item}: {item: {title: string; value: string | number, text?: string} }) {
    const {t} = useTranslation('realtime');
    return (
        <css.section>
            <css.title>{t(item.title)}</css.title>
            <css.amount>{formatNumber(item.value || 0, true) }</css.amount>
            {!!item.text && <css.text className='free-color'>{item.text}</css.text>}
        </css.section>
    );
}

function Insight({item}: {item: {value: any, text?: string} }){
    const {t} = useTranslation('realtime');

    return  (
        item?.text? <css.insightsItem>
            <css.insightsNumber className="free-color">{formatNumber(item.value)}</css.insightsNumber>
            <span>{t(item.text)}</span>
        </css.insightsItem>
            : <></>);
}

function CircleProgress({amount, sold, text}) {
    const {t} = useTranslation('realtime');

    return     <css.progressWrapper className='free-color' >
        <TicketProgress ticket={{color: DEFAULT_COLOR, amount, sold}} onClick={()=>{}} styleProps={{strokeTrailWidth: '0'}} >
            <css.goalText>{t(text)}</css.goalText>
        </TicketProgress>
    </css.progressWrapper>;
}

export {MainDetail, Insight, CircleProgress};
