import setGlobalState from '../setGlobalState';
import store from '../../store';
import {reloadIframe} from '../../layout/myIframe/MyIframe';
import {requestUser} from '../user/userActions';

export const authRefreshToken = async () => {
    const isMaster = store.getState().user?.isMaster;
    const socket = store.getState().auth?.socket;
    const res = await requestUser('auth.refreshToken', 'post', '/user/refreshToken/', {socket}, '/refreshToken');
    if(res.exp) {
        localStorage.setItem('exp', res.exp.toString());
        setGlobalState('auth.exp', res.exp.toString());
        if(isMaster){
            reloadIframe();
        }
    }
    if(res.csrfToken) {
        localStorage.setItem('csrfToken', JSON.stringify(res.csrfToken));
        setGlobalState('auth.csrfToken', res.csrfToken.toString());
    }
};

export const authRefreshTokenApplication = async () => {
    const res = await requestUser('auth.refreshToken', 'post', '/refreshTokenApplication/', {});
    if(res.applicationExp) {
        localStorage.setItem('applicationExp', res.applicationExp.toString());
        setGlobalState('auth.applicationExp', res.applicationExp.toString());
    }
    if(res.applicationToken){
        return res.applicationToken;
    }
    return null;
};