import * as css from './RunningButton.css';
import {useTranslation} from 'react-i18next';

function RunningButton() {
    const {t} = useTranslation('layout');

    return (
        <css.runningButton >
            {t('runningText')}
        </css.runningButton>
    );
}

export default RunningButton;
