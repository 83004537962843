import {useSelector} from 'react-redux';
import * as css from './WindowReports.css';
import {useTranslation} from 'react-i18next';
import RunningButton from '../../../components/runningButton/RunningButton';
import ReportCash from '../../../cash/reportCash/ReportCash';
import AccountRolePermission from '../../../components/rolePermission/AccountRolePermission';
export default function WindowReports(){

    const {t} = useTranslation('package');
    const title = useSelector((s: any) => s.account.title);

    return (
        <AccountRolePermission rolePermission={'manage package'} views={[]}>
            <css.reports>
                <css.titleRow>
                    <css.titleRowHeader>{t('title')} <RunningButton/></css.titleRowHeader>
                    <css.subTitleRow>{t('subTitle',{name:title})} </css.subTitleRow>
                </css.titleRow>
                <ReportCash/>
            </css.reports>
        </AccountRolePermission>
    );
}
