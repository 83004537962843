import {useRef, useEffect, useState} from 'react';
import * as css from './List.css';
import Item from '../item/Item';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {mobileScreen} from '../../globalVariables/GlobalVariables';
import UseMouseUpOutside from '../../hooks/UseMouseUpOutside';
import {Else, If, Then} from 'react-if';

//top & botton: gets number of picksels ex.top={25}
//left & right: gets the full value ex.left={"25px"}

//transition is 300ms

function List(props) {
    const {list=[], onClick, showClass, close, getMoreAction = null} = props;
    const {classes, title, t=v=>v, left, top, bottom, right, isChild, width = '250px',height=1000,
        arrowReturnClick, arrowIntoClick, arrow, fixedTop} = props;
    const containerRef=useRef(null);
    UseMouseUpOutside(containerRef,close);
    const ref =useRef(null);
    const window = useWindowDimensions();//instead of window.innerHeight

    // let line = 50;
    let line = css.LINE_HEIGH;
    let maxHeight = Math.min(line * 6,height);
    const headerHeight = title ? 60 : 0;
    let minHeight = list.length > 1 ? line * 2 : line;

    const [parentTop, setParentTop] = useState(null);
    const [parentBottom, setParentBottom] = useState(null);
    const [hasScrollMore, setHasScrollMore] = useState(true);

    useEffect(() => {
        if(fixedTop) {
            setParentTop(fixedTop);
        }
        else {
            setParentTop(ref.current.getBoundingClientRect().top - headerHeight);
            setParentBottom(window.height - (ref.current.getBoundingClientRect().top));
        }
    }, []);

    const [scrollY, setScrollY] = useState(calcHeight().height);
    const scrollJump = calcHeight().height;
    const hasScroll = (list.length * line) > calcHeight().height;

    function calcHeight() {
        let type = bottom ? 'bottom' : 'top';
        let listHeight = list.length * line < maxHeight ? list.length * line : maxHeight;
        let scroll = 0;
        let distance = type === 'top' ? (parseInt(top) + parentTop) : (parseInt(bottom) + parentBottom);
        let space = type === 'top' ? 30 : 0;
        let sendFixedTop = fixedTop;
        if(listHeight < list.length * line)
            scroll = 20;
        if(window.width > mobileScreen && (listHeight + scroll + headerHeight + distance + space) > window.height) {
            listHeight = window.height - distance - 20 - headerHeight - space;
            if(fixedTop)
                listHeight -= 50;
            if(listHeight < minHeight && listHeight < list.length * line) {
                listHeight = minHeight;
                if(type === 'top')
                    distance = window.height - listHeight - scroll - headerHeight - space;
                if(fixedTop)
                    sendFixedTop -= 20;
            }
        }

        if(fixedTop) {
            return {top: ((top ? top : 0) + sendFixedTop), bottom: '', height: listHeight};
        }
        return type === 'top' ? {top: (distance - parentTop), bottom: '', height: listHeight}
            : {top: '', bottom: distance - parentBottom, height: listHeight};
    }

    const scrollClick = (e) => {
        e.stopPropagation();
        if(getMoreAction){
            getMoreAction();
        }
        ref.current.scrollTo({top: scrollY + scrollJump, left: 0, behavior: 'smooth'});

    };
    const timer = useRef(null);

    const scrollToRef=(refTop)=> {
        timer.current = setTimeout(()=>{
            ref.current.scrollTo({top: refTop.current.offsetTop -55, left: 0});
        },500);
    };
    const onScroll = e => {
        setScrollY(e.target.scrollTop);
        setHasScrollMore(e.target.scrollTop + calcHeight().height >= list.length * line );
    };
    useEffect(() => () => clearTimeout(timer.current), []);

    const className=`${classes} ${showClass}`;

    return (
        <>
            <css.blur className={className}/>
            <css.List ref={containerRef} bottom={calcHeight().bottom} height={calcHeight().height + (hasScroll ? 20 : 0) + headerHeight} top={calcHeight().top}
                left={left} width={width} right={right}
                className={className + ' ' + (arrow && ' arrow')} isFixed={!!fixedTop }>
                <css.wrap className={className}>
                    {!!title &&
                        <css.ListTitle>
                            <If condition={typeof title === 'object'}>
                                <Then>
                                    {title}
                                </Then>
                                <Else>
                                    <css.ArrowWrap>
                                        {isChild && <css.headerArrow onClick={(e) => arrowReturnClick(e)} />}
                                    </css.ArrowWrap>
                                    {t(title)}
                                </Else>
                            </If>
                             
                        </css.ListTitle>}
                    <css.Items onScroll={onScroll} ref={ref} height={calcHeight().height}>
                        {list?.map((item, index) => {
                            return (<Item key={item.key ? item.key : index} index={index}
                                handleOnClick={item.list ? arrowIntoClick : (item.onClick || onClick)} scrollToMe={scrollToRef} item={item} t={t} close={close} dataCyName={item.dataCyName} />);
                        })}
                    </css.Items>
                    {hasScroll && <css.ScrollBottom className={(hasScrollMore || getMoreAction) ? '' : ' disable'} onClick={scrollClick} />}
                </css.wrap>
                <css.close className={className} onClick={close} height={calcHeight().height + (hasScroll ? 20 : 0) + headerHeight} />

            </css.List>
        </>
    );
}

export default List;
