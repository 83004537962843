import SettingItem from '../settingItem/SettingItem';
import MasterPermission from '../../components/rolePermission/MasterPermission';
import AddGooglePingItem from '../addGooglePingItem/AddGooglePingItem';
import {useSelector} from 'react-redux';
import {bounceMessageBlock} from '../../../layout/userMessagesBlock/UserMessageBlock';
import {updateEventSettings} from '../../../redux/event/eventActions';
import store from '../../../store';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {isMaster} from '../../../functions/permission';

export const onConfirmPortalRequest=(portalType)=>{
    bounceMessageBlock(i18next.t('event:confirmPortalTitle'), i18next.t('event:confirmPortalText',{eventName:store.getState().event.title}),
        {text:i18next.t('event:approve'),onClick:()=>updateEventSettings(`${portalType}Index`, 1, 'event')},{text:i18next.t('event:reject'),onClick:()=>updateEventSettings(`${portalType}Index`, 0, 'event')});
};

function MailingForm() {
    const {t} = useTranslation('event');
    const isShowInLive = useSelector(s=>s.event.settings.event.isShowInLive);
    const glatticketIndex = useSelector(s=>s.event.settings.event.glatticketIndex);
    
    const getStatus = () => {
        if(glatticketIndex==2)
            return 'message';
        else
            return glatticketIndex;
    };

    return (
        <>
            <SettingItem name={'tickchakIndex'}>
                {isMaster() && <SettingItem name={'promotionInTickchak'}/>}
            </SettingItem>
            <MasterPermission>
                <SettingItem name={'showInLive'}/>
                <SettingItem disabled={!isShowInLive} name={'promotionInLive'} />
            </MasterPermission>
            <SettingItem name={'glatticketIndex'} getStatus={getStatus} modeBtnText={glatticketIndex==2?t('isWait'):''}>
                {isMaster() && <SettingItem name={'promotionInGlatticket'}/>}
            </SettingItem>
            <SettingItem name={'isIndexed'}>
                <AddGooglePingItem />
            </SettingItem>
        </>
    );
}

export default MailingForm;
