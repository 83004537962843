import ReportCard from './reportCard/ReportCard';
import * as css from './ReportCash.css';
import {useSelector} from 'react-redux';
import {postEventReport} from '../../../redux/event/eventActions';
import {useEffect} from 'react';
import {getReportEvents,  getInvoiceDates} from '../../../redux/cash/cashActions';
import {useTranslation} from 'react-i18next';
import {getDate} from '../../../library/dates';

function ReportCash() {
    const {eventList,dateList,getEventListXHR, getDateListXHR}=useSelector((s:any) => s.cash);
    const {t} = useTranslation('cash');
    const renderEventList=eventList.map((event)=> {
        return {text: `${event.eid} - ${event.title}`, eid: event.eid};
    }
    );
    const renderDateList=dateList.map((bill)=> {
        return {text:getDate(bill.date), link: bill.invoice_link};
    }
    );
    const invoiceOnClick=(item)=>{
        item?.link && typeof item?.link === 'string' && window.open(item.link, '_blank');
    };
    const eventOnClick=(item)=>{
        postEventReport(item.eid).then();
    };

    useEffect(() => {
        if(!getEventListXHR)
            getReportEvents().then();
        if(!getDateListXHR)
            getInvoiceDates().then();
    }, []);

    return (
        <css.popContain>
            <ReportCard title={t('yourInvoices')} subTitle={t('invoiceSubTitle')}
                listButton={{title: '',text : t('selectDate'),list:renderDateList,onClick:invoiceOnClick}}
                whiteButton={{text: t('lastInvoice'), onClick:()=>invoiceOnClick(renderDateList[0])}}/>
            <ReportCard title={t('yourEvents')} subTitle={t('eventSubTitle')}
                listButton={{title: '',text :  t('selectEvent'),list:renderEventList,onClick:eventOnClick}}
                whiteButton={{text: t('lastEvent'), onClick:()=>eventOnClick(renderEventList[0])}}
            />
        </css.popContain>
    );
}

export default ReportCash;
