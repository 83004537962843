import {useEffect, useState} from 'react';
import * as css from './LoginForm.css';
import {useSelector} from 'react-redux';
import {XHR_SUCCESS_STATE} from '../../../../assets/constants/constants';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {forgetPassword} from '../../../../redux/user/userActions';
import {useTimer} from '../../../../hooks/useTimer';
import {getTime} from '../../../../library/dates';
import setGlobalState from '../../../../redux/setGlobalState';

function ForgetPasswordLink(){
    const forgetPasswordSuccess = useSelector((s: any)=>s.user.forgetPasswordXHR === XHR_SUCCESS_STATE);
    const [,, passwordHelper] = useField('password');
    const [emailField, emailMeta, emailHelper] = useField('email');
    const {timer,setTimer,startTimer} = useTimer(0);
    const {t} = useTranslation('login');
    const [deliveryCounter, setDeliveryCounter] = useState(0);

    useEffect(() => {
        setGlobalState('user.forgetPasswordXHR', null);
        if(emailField.value && !emailMeta.touched){
            emailHelper.setTouched(true, true);
        }
    }, [emailField.value]);
        
    const onForgotPasswordClick = () => {
        passwordHelper.setValue('');
        passwordHelper.setTouched(false);
        const isValid = emailMeta.touched && !emailMeta.error;
        if(isValid){
            forgetPassword(emailField?.value);
            setDeliveryCounter(old=>old+1);
            setTimer(3*60);
            startTimer();
        }
    };
        
    return (
        <css.forgotPassword>
            {forgetPasswordSuccess ? 
                <css.emailSuccessContainer>
                    { t('forgotPasswordEmailSuccess',{email:emailField.value})}
                    {timer>0 ? t('forgotPasswordEmailSuccessTimer',{timer: getTime(timer)})
                        :deliveryCounter >=5?
                            <>{t('tooManyAttempts')}</>
                            :<css.forgetAction onClick={onForgotPasswordClick}>{t('sendAgain')}</css.forgetAction>
                        
                    }
                </css.emailSuccessContainer>           
                : <>
                    <div />
                    <css.forgetAction onClick={onForgotPasswordClick}>{t('forgotPassword')}</css.forgetAction> 
                </>
            }
        </css.forgotPassword>
    );
}

export default ForgetPasswordLink;

