import styled from 'styled-components';
import {loginBackground, tickchakLogo} from '../../../../assets/symbols/images/Images.css';
import {goProduce} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const background = styled.div`
    ${p=>BGImageCenter(p.image,'cover')}
    width:100%;
    height:100% ;
    background-color:${p=>p.backgroundColor ? '#76D6E8' : 'none'} ;
    ${dFlexCenter}
    &::before{
        content:'Tickchak' ;
        position:absolute ;
        top:20px;
        inset-inline-end:20px;
        font-size: 26px;
        font-weight:800 ;
        z-index: ${zIndex.baseRaised};
    }
    &.isIframe{  
      //zoom:95%
    }
    ${mobileMedia}{
        &::before{
            content: '';
        }
    }
`;
export const stageContainer = styled.div`
    width:350px ;
    max-width:100% ;
    height:640px ;
    max-height:calc(100% - 50px);
    border-radius:10px ;
    background:${p=>p.isDark?'#43BAE7': '#A5EEFC'};
    box-shadow: 0 3px 26px #00000029;
    position:relative ;
    ${mobileMedia}{
        max-height:calc(100% - 100px);
        margin-bottom:50px ;
    }
`;

export const close = styled.div`
    width:30px;
    height:30px;
    border-radius:50%;
    background:#BFEEF7;
    position:absolute;
    inset-inline-start: 10px;
    top: 10px;
`;

export const top = styled.div` 
    border-radius:10px 10px 0 0 ;
    transition:height 300ms ;
    height:100px ;
    width:100% ;
    /* background-color:#43BAE7 ; */
    /* ${BGImageCenter(loginBackground, 'cover')} */
    &.height{
        height:50px ;
        &::after{
            top: -30px;
        }
    }
    position: relative;
    max-height: calc(100% - 370px);  
    pointer-events: none;

  &::after{
        content:'' ;
        ${BGImageCenter(tickchakLogo,'90px')}
        position:absolute ;
        bottom:-30px;
        height:100px ;
        width:100% ;
    }

`;
export const childrenWrap = styled.div`
    height:calc(100% - 100px) ;
    &.height{
        height:calc(100% - 100px) ;
    }
`;
export const goAndProduce = styled.div`   
        z-index:${zIndex.baseRaised};
        width:110px ;
        height:80px ;
        position:absolute ;
        bottom:-28px;
        left:calc(50% - 55px) ;
        ${dFlexCenter};
        ${BGImageCenter(goProduce, '110px')}
        ${mobileMedia}{
            &.keyboardOpen{
                display: none;
            }
    }
`;

export const nav = styled.div`
${dFlexCenter};
justify-content: space-between;
width:50px;
position: absolute;
inset-inline-end: 20px;
bottom: 20px;
`;
export const circle = styled.div`
cursor: pointer;
width: 13px;
height: 13px;
border-radius: 50%;
background-color: white;
&.current{
    ${dFlexCenter}
    &::before{
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #CCD232;
    }
}
`;
