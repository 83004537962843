import MobilePopup from '../../../component/newPopup/mobilePopup/MobilePopup';
import ReportCash from './ReportCash';
import useMobile from '../../../hooks/useMobile';

function WrapReportCash({show}) {
    const isMobile = useMobile();

    return (
        <MobilePopup close={show.close} showClass={show.showClass} title={undefined} list={undefined} forwardRef={undefined} dataCyName={undefined} height={isMobile?'max-content':'470px'} >
            <ReportCash/>
        </MobilePopup>
    );
}

export default WrapReportCash;
