import styled from 'styled-components';
import {BLACK, dFlexCenter, PERSIAN_RED, WHITE} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const container = styled.div`
    width: 100%;
`;

export const tooltip = styled.div<{display: boolean, height:number}>`
    position: relative;
    border-radius: 3px;
    font-size: 12px;
    margin-block: ${p=>p.display? 12 : 7}px;
    & *, &::before{
        visibility: ${p=>p.display? 'visible':'hidden'}  ;
        height: ${p=>p.display? 16 : 0}px ;
    }
    &, & *{
        transition: all 0.3s;
    }
    height: ${p=>p.display? p.height : 0}px ;
    width: 100%;
    background-color: ${WHITE};
    &::before{
        content: '';
        width: 13px;
        height: 13px;
        top: -6px;
        inset-inline-start: 31px;
        position: absolute;
        transform: rotate(45deg);
        background-color: ${WHITE};
    }

`;
export const length = styled.div`
    margin-top: -6px;
    display: flex;
    gap: 3px;
    margin-inline-start: 5px;
    z-index: ${zIndex.baseHigh};
    position: relative;
`;
export const lengthRule = styled.div<{isValid: boolean}>`
    color: ${p=>p.isValid? BLACK : PERSIAN_RED};
`;
export const rule = styled.div`
    display: flex;
    gap: 5px;
    margin-inline: 5px;
    font-size: 12px;
`;

export const icon = styled.div`
    width: 8px;
    ${dFlexCenter}
`;