import {authRefreshTokenApplication} from '../redux/auth/authActions';
import store from '../store';
import {APPLICATION_LOGIN_PATH} from '../config/config';

const refreshTokenApplicationAndRedirect = async () => {
    const token = await authRefreshTokenApplication();
    if(token) {
        // Get the current state from store if needed
        const state = store.getState();
        const csrfToken = state.auth?.csrfToken;
        const user = state.user;
		
        window.location.href = `${APPLICATION_LOGIN_PATH}?applicationToken=${token}&csrf=${csrfToken}&user=${JSON.stringify(user)}`;
    }
};

export {refreshTokenApplicationAndRedirect};