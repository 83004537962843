import * as css from './Permissions.css';
import {useEffect, useState,Fragment} from 'react';
import SubPermission from '../subPermissions/SubPermission';
import PermissionsItem from '../permissionsItem/PermissionsItem';
import {useSelector} from 'react-redux';
import useShow from '../../../../hooks/useShow';
import UpgradePopup, {permissionToCamelCase} from '../upgradePopup/UpgradePopup';
import {getAccountPermissions} from '../../../../redux/account/accountActions';

const categories =
    {
        general:[ 'languageAdvanced', 'reserved', 'crmImport', 'smsEnter' ],
        advanced: ['publish', 'publishPixel','googleWalletTicket','publishEmbed','domain' ,'whatsApp'],
        eventTypes:['ticket' ,'area', 'scheduled', 'timetable', 'course', 'cluster', 'undatedEvent', 'eventCash', 'home','hub','givechak'],
        collection:['billing','payments','suspended',  'boxoffice','currency'],
        ordersCancellation:['userCancelTicket','defaultUserCancelTicket'],
        coupons: ['crowds','couponVoucher','externalVoucher' ],
    };

const master= ['creditRefund','ticketsEmail','cancellationFee','ticketsBranding','packageCash', 'customPixel', 'promotionMessage', 'glatticketShow'];

// master:[ 'creditRefund','מזומן כברירת מחדל','דף חסום לקהל ','packageCrm','ticketsEmail','cancellationFee','ticketsBranding','packageCash' ]

function Permissions() {
    const permissions = useSelector(state => state.account.permissions);
    const [indexPopup, setIndexPopup] = useState(null);
    const isMasterState = useSelector(state => state.ui.isMasterState);

    useEffect( () => {
        if(!permissions.length)
            getAccountPermissions();
    }, []);//??? is it necessary?

    const {open, close, isOpen, showClass} = useShow();

    const openPopup = index => {
        setIndexPopup(index);
        open();
    };
    const getDisabled = {
        defaultUserCancelTicket: () => {
            const userCancelTicketPermission = permissions.find(p => permissionToCamelCase(p.permission) === 'userCancelTicket');
            return userCancelTicketPermission && userCancelTicketPermission.status === 0;
        },
        default:false
    };
    const getPermissionItem=(permission)=>{
        const index = permissions?.findIndex((i) => permission ===permissionToCamelCase(i.permission));
        if(index!==-1)
            return <PermissionsItem key={index} index={index} disabled={getDisabled[permission]?getDisabled[permission]():getDisabled.default} openPopup={openPopup} permission={permissionToCamelCase(permissions[index].permission)} />;
    };

    return (<>
        <css.container>
            {Object.entries(categories).map(([key,value]) =>
                <Fragment key={key}>
                    <SubPermission name={key} />
                    {value.map(permission=>getPermissionItem(permission))}
                </Fragment>
            )}
            {isOpen && <UpgradePopup index={indexPopup} showClass={showClass} close={close} />}
        </css.container>
        { isMasterState&&
        <css.masterWrap>
            <SubPermission name='master' />
            <css.master>
                {master.map(permission=>getPermissionItem(permission))}
            </css.master>
        </css.masterWrap> }
    </>
    );
}

export default Permissions;
