import styled from 'styled-components';
import {
    BLACK,
    PINK_RED
} from '../../../globalVariables/GlobalVariables';
export const runningButton = styled.span`
    background-color:${PINK_RED};
    color: ${BLACK};
    border-radius: 15px;
    padding:4px 15px;
    font-size: 14px;
    font-weight: 500;
    margin-inline-start:10px;
`;
