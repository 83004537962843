import styled from 'styled-components';
import {mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {input} from '../../components/loginInput/LoginInput.css';
import zIndex from '../../../../globalVariables/zIndex';
import {likeBefore, wrapApple} from '../../signIn/loginForm/LoginForm.css';

export const wrapper = styled.div`
    height:75px ;
    border-bottom:1px solid #00000029;
    position:relative ;
    margin-bottom:30px ;
    &::after{
        content:"${p => p.text}";
        width: 100px;
        height: 20px;
        position: absolute;
        left: calc(50% - 50px);
        background-color: #A5EEFC;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        bottom: -10px;
    }

`;

export const wrapGoogle=styled.div`
    height:40px ;
`;

export const likeBeforeSignUp=styled(likeBefore)`

`;
export const apple=styled(wrapApple)`
   
`;
export const container = styled.div`
padding: 35px;
${input}{
    margin-bottom: 22px;
}
${mobileMedia}{
    padding: 20px 35px;
}
`;

export const header = styled.div`
padding: 20px 0 20px 0;
font-size: 22px;
font-weight: 800;
text-align: center;
${mobileMedia}{
    padding: 5px 0 5px 0;
}
`;

export const form = styled.div`
${mobileMedia}{
    height: calc(100vh - 200px);
    ${scrollbar}
}

`;
export const button = styled.div`
padding-top: 10px;
${mobileMedia}{
    padding-top: 0;
}
`;

export const linkWarp=styled.div`
      position: relative;
      font-size: 12px;
      font-weight: 800;
      z-index: ${zIndex.baseHigh};
      margin-top:5px;
      inset-inline-start: -15px;
      ${mobileMedia}{
        inset-inline-start: 0px;
    }
      a{
            text-decoration: underline;
            color: #007acc;
            margin-inline-start: 3px;
      }
`;