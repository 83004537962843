import * as css from './ProgressBarThin.css';
import {useTranslation} from 'react-i18next';

function ProgressBarThin({amount, progress}) {
    
    const {t} = useTranslation('login');
    const status=[t('weak'),t('weak'),t('almost'),t('almost'),t('strong')];
    const progressIndex = parseInt(progress/amount*5) - 1 || 0;

    return (

        <css.ProgressBarFrame className='progressBarFrame'>
            <css.ProgressBar className='progressBar'>
                <css.ProgressBarChild progressIndex={progressIndex} amount={amount} progress={progress} className={' progressBarChild '} />
            </css.ProgressBar>
            <css.pStatus className="pStatus" progressIndex={progressIndex}>{status[progressIndex]}</css.pStatus>
        </css.ProgressBarFrame>

    );
}

export default ProgressBarThin;
