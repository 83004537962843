import createReducer from '../createReducer';

let user = JSON.parse(localStorage.getItem('user'));
let exp = JSON.parse(localStorage.getItem('exp'));
let csrfToken = JSON.parse(localStorage.getItem('csrfToken'));

let applicationExp = JSON.parse(localStorage.getItem('applicationExp'));

const initialState = {
    isLoggedIn:!!user,
    exp: exp?exp:0,
    message:'',
    token:null,
    socket: {},
    csrfToken: csrfToken || null,
    isApplication: false,
    applicationExp: applicationExp,
    
};

const reducer = createReducer('auth',initialState);

export default reducer;