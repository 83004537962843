import * as css from './SettingItem.css';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ModeBtn, {statuses} from '../../../component/modeBtn/ModeBtn';
import useShow from '../../../hooks/useShow';
import Explanation from '../explanation/Explanation';
import SubSettingForm from '../subSettingForm/SubSettingForm';
import SubSettingItem from '../subSettingItem/SubSettingItem';
import {updateEventSettings} from '../../../redux/event/eventActions';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import useNavigationLink from '../../../hooks/useNavigationLink';
import {updateFullSend, updateNotification} from './settingsItemFunctions';
import convertToCamelCase from '../../../functions/convertToCamelCase';
import hasSpecificTranslate from '../../../library/hasSpecificTranslate';
import {hasAccountAccess, isMaster} from '../../../functions/permission';

function SettingItem({name, settingCategory = 'event', children, disabled, permission, rolePermission,settingChildren, getStatus, modeBtnText}) {
    const {t} = useTranslation('event');
    const tLayout = useTranslation('layout');
    const {open, close, isOpen, showClass} = useShow();
    const subSettingShow = useShow();
    const event = useSelector(state => state.event);
    const hasExplanation = t(`${name}Desc`) !== `${name}Desc` ? true : false;
    const settingXHR = useSelector(state => state.forms.eventSettings[`${name}XHR`]);
    let settings = useSelector(state => state.event.settings?.[settingCategory]);
    let setting = settings?.[name];
    const account = useSelector(s=>s.account);
    const verificationPop = useNavigationLink('verificationPop', 'open');

    if(name === 'notification') {
        setting = settings?.emailSend || settings?.smsSend;
    }
    const {active, inactive, message} = statuses;
    const isFirstRun = useRef(true);

    useEffect(() => {
        if(setting==1 || setting==true|| (name === 'limit' && setting))
            subSettingShow.open();
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if(!setting)
            subSettingShow.close();
    }, [setting]);

    const updateSetting = () => {
        switch (name) {
            case 'limit':
                updateEventSettings(name, setting ? false : 'tickets', settingCategory);                
                break;
            case 'notification':
                updateNotification(setting);
                break;
            case 'fullSend':
                updateFullSend(name,settings,!setting);
                break;        
            default:
                updateEventSettings(name, !setting, settingCategory, setting ? null : permission, rolePermission);
                break;
        }
    };

    const [isWarningOpen, setIsWarningOpen] = useState(false);

    const openMessage=(text)=>{
        setIsWarningOpen(true);
        bounceMessage(text,'attention',undefined,{onClick:()=>setIsWarningOpen(false)});
    };

    const toggleClick = () => {
        if(name==='hasBillings'&&setting)  {
            if(event.type==='course'){
                openMessage(t('billingsWarning'));
                return;
            }
        }

        if(name === 'glatticketIndex') {
            if(!isMaster() && !hasAccountAccess('glatticket show')) {
                if(!setting){
                    setIsWarningOpen(true);
                    bounceMessage(t('glatticketMessage'));
                    updateEventSettings(name, 2, settingCategory, setting ? null : permission, rolePermission);
                    return;
                } 
                else if(setting===2){
                    return;
                }
                else {
                    bounceMessage(t('glatticketErrorMessage'));
                }
            }
            else {
                updateSetting();
            }
        }

        if(name === 'tickchakIndex') 
            if(!isMaster()&&setting){
                bounceMessage(t('glatticketErrorMessage'));
            }
        // if(name==='susStatus'&&setting)
        //     if(event.hasSuspendedTransaction){
        //         openMessage(t('suspendedWarning'));
        //         return;
        //     }
      
        if(name!='active' && (!setting || name!='newsletter')){
            updateSetting();
            return;
        }
        const messageProps=['attention',
            {text:tLayout.t('btnMessage1'), onClick:()=>{ updateSetting(); setIsWarningOpen(false);}},
            {text:tLayout.t('btnMessage'),onClick:()=>{setIsWarningOpen(false);}}
        ];

        if(name === 'active'){
            if(setting){
                setIsWarningOpen(true);
                bounceMessage(tLayout.t('userMessages', {event: event?.title}),...messageProps);
            }
            else {
                if(account.ownerEmailVerified===1 && account.termsVerified && account.bankVerified)
                    updateSetting();
                else {
                    if(account.userAccountRole === 'owner')
                        bounceMessage(tLayout.t('weWantToRecognizeYou'), 'attention', {text:tLayout.t('verify'), onClick:()=>{verificationPop.open();}}, {text:tLayout.t('anotherTime'), onClick:()=>{setIsWarningOpen(false);}} );
                    else
                        bounceMessage(tLayout.t('ownerNeedsToVerify', {owner: account?.title}), 'attention');
                }
            }
        }
        else {//'newsletter'
            setIsWarningOpen(true);
            bounceMessage(t(`${name}Warning`),...messageProps);
        }
    };

    const getBtnStatus = () => {
        if(getStatus)
            return getStatus();
        return isWarningOpen ? message : (setting ? active : inactive);
    };

    return (
        <>
            <SubSettingItem text={name === 'limit' ? t`limitOrder` : hasSpecificTranslate(convertToCamelCase(`${name} ${event.type}`),t) || t(`${name}`)} hasExplanation={hasExplanation} disabled={disabled} >
                {settingChildren?settingChildren:<css.toggle>
                    <ModeBtn click={toggleClick} status={getBtnStatus()} requestStatus={settingXHR} disabled={disabled} dataCyName={name} text={modeBtnText}/>
                </css.toggle>}
                {hasExplanation && <css.explanationIcon data-cy="explanationIcon" onClick={isOpen ? close : open} > </css.explanationIcon>}
            </SubSettingItem>
            {isOpen && <Explanation name={name} close={close} showClass={showClass} />}
            {children&&(subSettingShow.isOpen || (name === 'active' && !subSettingShow.isOpen) )  &&
             <SubSettingForm className={name !== 'active'? subSettingShow.showClass :(!subSettingShow.isOpen? '  show ': '  ')   + ' ' + (name === 'fbImgFetch' && ' overflowHidden')} name = {name}>{children}</SubSettingForm>}
        </>
    );
}
export default SettingItem;