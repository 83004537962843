import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Copy from '../component/copy/Copy';
import {crm, nisBell, packageImage, team, mailingIcon, reportsIcon} from '../assets/symbols/symbols/Symbols.css';
import {packageList, packageListClick} from '../layout/events/packageManagement/PackageManagement';
import AccountVerificationMessage from '../layout/topMenu/accountVerificationMessage/AccountVerificationMessage';
import setGlobalState from '../redux/setGlobalState';
import {hasAccountRoleAccess} from '../functions/permission';

export default function useAccountMenu(close=()=>{}){

    const history = useHistory();
    const account = useSelector(state => state.account);
    const {t} = useTranslation('package');
    const layoutT=useTranslation('layout');
    // const eid=useSelector(s=>s.event.eid);

    return (otherPid=null,title=null)=>{
        const currentPid=otherPid||account?.pid;
        const currentTitle=title||`${layoutT.t('accountDetails')} -  ${account?.title||''}`;

        const onClick = (item) => {
            history.push({
                pathname: '/p/' + currentPid + '/' + item.text
            });
            close();
        };
        //const prePath = eid&&!otherPid ? '/e/' + eid + '/' : '/p/' + account.pid + '/';
        const accountList =
        {
            title: currentTitle,
            arr: [
                {
                    key: 'subscription',
                    title: 'subscription',
                    text: 'subscription',
                    icon:crm,
                    onClick: ()=> packageListClick({text: 'subscription', title: 'subscription'}, history, close,otherPid)
                },
                {
                    key: 'crmAccount',
                    title: 'crmAccount',
                    text: 'crm',
                    icon:crm,
                    onClick
                },
                {
                    key: 'packageSettings',
                    title: 'packageSettings',
                    text: 'package',
                    icon:packageImage,
                    list: {
                        title: 'package',
                        arr: packageList,
                        onClick: (item) => packageListClick(item, history, close,otherPid)
                    },
                    onClickMenu:()=>packageListClick({text:'package',title: 'packagePermissions'}, history, close,otherPid)
                },
                {
                    key: 'teamAccount',
                    title: 'teamAccount',
                    text: 'team',
                    icon:team,
                    onClick: () => packageListClick({text:'team'}, history, close,otherPid)
                },
                {
                    // text: 'accountsCash',
                    // icon:nisBell,
                    // list: {
                    //     title: 'cash',
                    //     arr: [{text: 'cash', permission: 'package cash'}
                    //         , {title: 'bank', text: 'cash/bank'}],
                    //     onClick
                    // }
                    key: 'accountsCash',
                    title: 'accountsCash',
                    text: 'cash',
                    icon:nisBell,
                    onClick
                },
                {
                    key: 'crowd',
                    text: 'crowd',
                    icon:team,
                    list: {
                        title: 'crowd',
                        arr: [{text: 'crowd'}
                            , {title: 'coupons', text: 'crowd/coupons'}],
                        onClick
                    },
                    onClickMenu:onClick
                },
                {
                    key: 'mailing',
                    text:'mailing',
                    onClick,
                    icon:mailingIcon
                },
                {
                    key:'packagePid',
                    text: t('packagePid')+currentPid,
                    rightChildren:<Copy link={currentPid}/>
                },
                hasAccountRoleAccess('manage package')&&{
                    key:'reports',
                    text: t('reports'),
                    onClick: () => {
                        setGlobalState('ui.show.isOpenReportCash',true);
                        close();
                    },
                    icon:reportsIcon
                },
            ]
        };
        if(!(account.ownerEmailVerified===1 && account.termsVerified && account.bankVerified)){
            accountList.arr.unshift(
                {
                    key: 'accountVerification',
                    children:<AccountVerificationMessage/>
                }
            );
        }
        return accountList;
    };
}
